import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'wouter';
import { selectLoggedIn } from '../../features/authorization/AuthSlice';

const ProtectedRoute: React.FC<RouteProps> = ({ path, children }) => {
    const authenticated = useSelector(selectLoggedIn);

    if (authenticated === undefined) return <></>;

    const returnElement = authenticated ? (
        <Route path={path}>{children}</Route>
    ) : (
        <Redirect to="/login" />
    );

    return returnElement;
};

export default ProtectedRoute;
