/**
 * To Remove www from redirectUri
 */
const getCleanedOrgin = (): string => {
  let origin: string = window.location.origin;

  if (origin.startsWith("https://www.")) {
    origin = origin.replace("https://www.", "https://");
  } else if (origin.startsWith("http://www.")) {
    origin = origin.replace("http://www.", "http://");
  }

  return origin;
};

/**
 * Redirect to SSO login page
 */
export const loginSSo = () => {
  const origin = getCleanedOrgin();

  const url = `${process.env.REACT_APP_AUTH_URL}${process.env.REACT_APP_AUTH_URL_ENDPOINT_LOGIN}?redirectUri=${origin}${process.env.REACT_APP_LOGIN_REDIRECT_URI}`;

  window.location.href = url;
};

/**
 * Redirect to SSO logout page
 */
export const logoutSSO = () => {
  console.log("User logout");
};
