import React from "react";

import "./koneBrandMark.scss";

const KoneBrandMark: React.FC = () => {
  return (
    <img
      id="svg"
      src={require("../../assets/images/KONE_Logo.png")}
      alt="KONE Logo"
      style={{ filter: "brightness(1)" }}
      className="koneBrandMark"
    />
  );
};

export default KoneBrandMark;
