import React, { useState } from "react";
import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { StationLevels } from "./StationLevel";
import { useAppSelector } from "../../app/hooks";
import { selectCustomerInfo } from "../../features/multiStepForm/FormSlice";
import { useDispatch } from "react-redux";
import {
  SiteAssetApi,
  SiteDetails,
} from "../../utils/CustomerDeploymentApi/CDSiteAssestApi";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import { useQuery } from "react-query";
import { SelectEquipment } from "./SelectEquipment";
import { SelectSensors } from "./SelectSensors";
import { BackButton } from "../BackButtonWitHeading/BackButtonWitHeading";
import { SelectSensorLevel } from "./SelectSensorLevel";

export const StationInfoPage: React.FC = () => {
  // Env's
  const customerInfo = useAppSelector(selectCustomerInfo);
  const token = useAppSelector(selectAuthTokens);

  // Check if site exists
  // const {
  //   data: custSiteData,
  //   isSuccess: isCustSiteSuccess,
  //   isFetching: fetchingCustSiteData,
  // } = useQuery(
  //   SiteAssetApi.queryNames.GETSITE,
  //   () =>
  //     SiteAssetApi.getSite(
  //       token!.id_token,
  //       customerInfo.customerDetails.customerId
  //     ),
  //   {
  //     refetchOnWindowFocus: false, // Prevent refetching when window regains focus
  //     refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
  //     refetchInterval: false, // Disable automatic refetching
  //     staleTime: 5000,
  //     onSuccess: (data) => {
  //       if (data) {
  //         setSiteExists(true);
  //         console.log("Site exists", data[0]["site_name"]);
  //       } else {
  //         setSiteExists(false);
  //       }
  //     },
  //   }
  // );

  // Call Api to create site if not exists
  // const siteDetails: SiteDetails = {
  //   name: customerInfo.siteInfo!.site_name,
  //   type: customerInfo.siteInfo!.site_type,
  //   description: `Site for customer ${customerInfo.customerDetails.customerName}`,
  //   is_active: true,
  // };

  // const {
  //   data: stationData,
  //   isSuccess: isStationSuccess,
  //   isFetching: fetchingStationData,
  // } = useQuery(
  //   SiteAssetApi.queryNames.CREATESITE,
  //   () =>
  //     SiteAssetApi.createSite(
  //       token!.id_token,
  //       customerInfo.customerDetails.customerId,
  //       siteDetails
  //     ),
  //   {
  //     refetchOnWindowFocus: false, // Prevent refetching when window regains focus
  //     refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
  //     refetchInterval: false, // Disable automatic refetching
  //     staleTime: 5000,
  //     enabled: siteExists === false, // Only run this query if site does not exist
  //     onSuccess: (data) => {
  //       console.log("Site created successfully", data.site_id);
  //     },
  //   }
  // );

  // if (isStationSuccess) {
  //   console.log("Site created successfully", stationData.site_id);
  // }
  // if (fetchingStationData) {
  //   return <Text>Loading...</Text>;
  // }

  // return (
  //   <>
  //     <BackButton displayName={`${customerInfo.siteInfo!.site_type}: ${customerInfo.siteInfo?.site_name}`} />
  //     <Box borderBottom="2px" borderColor="gray.300" mb={5} />
  //     <HStack>

  //     </HStack>
  //     {/* <Box>
  //       <Text>Add Floors to Metro Station</Text>
  //       <StationLevels
  //         customerId={customerInfo.customerDetails.customerId}
  //         id_token={token!.id_token}
  //         siteId={customerInfo.siteInfo!.site_id}
  //         onLevelDataReceived={handleLevelData}
  //       />
  //       <SelectEquipment
  //         customerId={customerInfo.customerDetails.customerId}
  //         id_token={token!.id_token}
  //         siteId={customerInfo.siteInfo!.site_id}
  //       />
  //       <SelectSensors
  //         customerId={customerInfo.customerDetails.customerId}
  //         id_token={token!.id_token}
  //         siteId={customerInfo.siteInfo!.site_id}
  //         levelData={levelData!}
  //       />
  //     </Box> */}
  //   </>
  // );
  return (
    <>
      <BackButton
        displayName={`${customerInfo.siteInfo!.site_type}: ${
          customerInfo.siteInfo?.site_name
        }`}
      />
      <Box borderBottom="2px" borderColor="gray.300" mb={5} />
      <Tabs variant="unstyled">
        <TabList borderBottom="1px solid" borderColor="gray.200">
          <Tab
            fontSize={"16px"}
            fontFamily={"var(--fontfamilymain)"}
            fontWeight="bold"
            _selected={{ borderBottom: "2px solid", borderColor: "blue" }}
            _hover={{ borderBottom: "2px solid", borderColor: "blue.100" }}
            p="4"
          >
            Levels
          </Tab>
          <Tab
            fontSize={"16px"}
            fontFamily={"var(--fontfamilymain)"}
            fontWeight="bold"
            _selected={{ borderBottom: "2px solid", borderColor: "blue" }}
            _hover={{ borderBottom: "2px solid", borderColor: "blue.100" }}
            p="4"
          >
            Equipment
          </Tab>
          <Tab
            fontSize={"16px"}
            fontFamily={"var(--fontfamilymain)"}
            fontWeight="bold"
            _selected={{ borderBottom: "2px solid", borderColor: "blue" }}
            _hover={{ borderBottom: "2px solid", borderColor: "blue.100" }}
            p="4"
          >
            Sensors
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Text>Add Floors to Metro Station</Text>
            <StationLevels
              customerId={customerInfo.customerDetails.customerId}
              id_token={token!.id_token}
              siteId={customerInfo.siteInfo!.site_id}
            />
          </TabPanel>
          <TabPanel>
            <SelectEquipment
              customerId={customerInfo.customerDetails.customerId}
              id_token={token!.id_token}
              siteId={customerInfo.siteInfo!.site_id}
            />
          </TabPanel>
          <TabPanel>
            <SelectSensorLevel
              customerId={customerInfo.customerDetails.customerId}
              id_token={token!.id_token}
              siteId={customerInfo.siteInfo!.site_id}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
