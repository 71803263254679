import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface SidebarState{
    mainSidebar: boolean;
}

const initialState: SidebarState = {
    mainSidebar: true
}

export const SideBarSlice = createSlice({
    name: "SideBar",
    initialState,
    reducers: {
        setMainSideBarStatus: (state, action: PayloadAction<boolean>) => {
            state.mainSidebar = action.payload;
        }
    }
})

/*===============================
            Actions
===============================*/

export const {
    setMainSideBarStatus
} = SideBarSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectMainSidebarStatus = (state: RootState) => state.sidebar.mainSidebar;

export default SideBarSlice.reducer;