import React from "react";
import "./App.scss";
import LoginRoute from "./routes/LoginRoute/LoginRoute";
import { Redirect, Route } from "wouter";
import LoginCallback from "./routes/LoginRoute/LoginCallBack";
import ProtectedRoute from "./routes/protected/ProtectedRoute";
import LogoutRoute from "./components/Logout/LogoutRoute";
import { TopNavBar } from "./components/TopNavbar/TopNavbar";
import DeploymentForm from "./components/DeploymentForm/DeploymentForm";

const App: React.FC = () => {
  return (
    <div className="App">
      <Route path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login">
        <LoginRoute />
      </Route>
      <Route path="/login-callback">
        <LoginCallback />
      </Route>
      <ProtectedRoute path="/multi-form">
        <TopNavBar />
        <DeploymentForm />
      </ProtectedRoute>
      <ProtectedRoute path="/logout">
        <LogoutRoute />
      </ProtectedRoute>
    </div>
  );
};

export default App;
