import React from "react";
import { useState } from "react";
import { Text, HStack, Button, Box } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import {
  Levels,
  selectSiteLevelInfo,
} from "../../features/siteLevel/siteLevelSlice";
import { SelectSensors } from "./SelectSensors";

interface SensorsCompProps {
  customerId: string;
  id_token: string;
  siteId: string;
}

export const SelectSensorLevel: React.FC<SensorsCompProps> = ({
  customerId,
  id_token,
  siteId,
}) => {
  // Variable's
  const siteLevelData = useAppSelector(selectSiteLevelInfo);
  const [isLevelSelected, setIsLevelSelected] = useState<boolean>(false);
  const [selectedLevel, setSelectedLevel] = useState<Levels | undefined>();

  // set selected level
  const handleLevelSelect = (level: Levels) => {
    setSelectedLevel(level);
    setIsLevelSelected(true);
  };

  // deselect level
  const deselectLevel = () => {
    setIsLevelSelected(false);
    setSelectedLevel(undefined);
  };

  return (
    <>
      {!isLevelSelected && (
        <Box>
          <Text>Select the Level to Add Sensors</Text>
          <Text>{selectedLevel ? selectedLevel.level_id : "not selected"}</Text>
          {siteLevelData.map((level) => (
            <HStack key={level.level_id}>
              <Text>{level.level_name}</Text>
              <Button onClick={() => handleLevelSelect(level)}>Select</Button>
            </HStack>
          ))}
        </Box>
      )}
      {isLevelSelected && selectedLevel &&  (
        <>
          <Text>{selectedLevel ? selectedLevel.level_id : "glitch"}</Text>
          <Text>{selectedLevel ? selectedLevel.level_name : "glitch"}</Text>
          <Button onClick={() => deselectLevel()}>Go Back</Button>
          <SelectSensors customerId={customerId} id_token={id_token} siteId={siteId} level_id={selectedLevel.level_id}/>
        </>
      )}
    </>
  );
};
