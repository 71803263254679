interface UploadUrl {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    "x-amz-security-token": string;
    policy: string;
    signature: string;
  };
}

interface UploadData {
  id: string; // asset id
  name: string;
  type: string;
  description: string;
  upload_url: UploadUrl;
}

export interface LevelAssetsDetails {
  /**
   * Name of asset/FILE
   */
  name: string;
  /**
   * Type of asset/FILE
   */
  type: string;
  /**
   * Description of asset/FILE
   */
  description: string;
}

/**
 * API URL
 */
const apiUrl = process.env.REACT_APP_CUSTOMER_DEPLOYMENT_INVOKE_URL;

/**
 * Query names
 */
const queryNames = {
  GETCUSTOMER: "/customer",
  SITE: "/site",
  LEVEL: "/level",
  LEVELASSETS: "/asset",
  IMPORTLEVEL: "/import",
};



const createLevelAsset = async (
  token: string,
  customerId: string,
  siteId: string,
  sitelevelId: string,
  levelAssetsDetails: LevelAssetsDetails
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.SITE}/${siteId}${queryNames.LEVEL}/${sitelevelId}${queryNames.LEVELASSETS}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(levelAssetsDetails),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to create asset for level ${sitelevelId}`);
  }

  return response.json();
};

export const uploadAssestToLevel = async (
  token: string,
  customerId: string,
  siteId: string,
  sitelevelId: string,
  levelAssetsDetails: LevelAssetsDetails,
  asset: File
) => {
  const formData = new FormData();

  const data: UploadData = await createLevelAsset(
    token,
    customerId,
    siteId,
    sitelevelId,
    levelAssetsDetails
  );

  const url = data["upload_url"]["url"];
  Object.entries(data["upload_url"]["fields"]).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append("file", asset);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("File uploaded successfully");
      //   await importStations(token, customerId, data.id);
      //   console.log("Stations imported successfully");
    } else {
      console.error("File upload failed", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading file", error);
  }
};
