import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../features/authorization/AuthSlice";
import SideBarReducer from "../features/sidebar/SideBarSlice";
import FormReducer from "../features/form/FormSlice";
import MultiFormReducer from "../features/multiStepForm/FormSlice";
import SiteLevelReducer from "../features/siteLevel/siteLevelSlice";
import storageSession from "redux-persist/lib/storage/session";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
    key: "root",
    version: 1,
    storage: storageSession,
};

const reducer = combineReducers({
    auth: AuthReducer,
    sidebar: SideBarReducer,
    formState: FormReducer,
    multiForm: MultiFormReducer,
    siteLevel: SiteLevelReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
