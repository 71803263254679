import { defineStyleConfig } from "@chakra-ui/react";

export const Input = defineStyleConfig({
  baseStyle: {
    field: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontFamily:
        "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      padding: 0,
      outline: "none",
      borderRadius: "100px",
      border: "2px solid var(--color-border-strong, #959595)",
    },
  },
  variants: {
    default: {
      field: {
        _disabled: {
          bg: "var(--background-disabled, #F1F1F1)",
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: "14px",
        height: "32px",
        padding: "8px 20px",
      },
    //   adon: { padding: "0 10px" },
    },
    md: {
      field: {
        fontSize: "16px",
      },
    },
    lg: {
      field: {
        fontSize: "18px",
      },
    },
  },
  defaultProps: {
    variant: "default",
    size: "sm",
  },
});

export const InputGroup = defineStyleConfig({
  baseStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "100px",
    gap: "8px",
  },
  sizes: {
    sm: { w: "300px", padding: "8px 8px 8px 18px", h: "32px" },
    md: { w: "400px", padding: "12px 12px 12px 24px", h: "48px" },
    lg: { w: "500px", padding: "18px 18px 18px 32px", h: "64px" },
  },
  variants: {
    default: {
      border: "2px solid var(--color-border-strong, #959595)",
      bg: "white",
      _hover: {
        border: "2px solid var(--kone-blue, #1450f5)",
        _disabled: {
          bg: "var(--background-disabled, #F1F1F1)",
          border: "none",
        },
      },
      _disabled: {
        bg: "var(--background-disabled, #F1F1F1)",
      },
      _invalid: {
        border: "3px solid var(--border-error, #F51414)",
      },
      _focusWithin: {
        border: "3px solid var(--kone-blue, #1450f5)",
      },
    },
    disabled: {
      bg: "var(--background-disabled, #F1F1F1)",
      color: "var(--text-lighter, #727272)",
    },
    invalid: {
      border: "3px solid var(--border-error, #F51414)",
    },
  },
  defaultProps: {
    variant: "default",
    size: "md",
  },
});
