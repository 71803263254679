import { defineStyleConfig } from "@chakra-ui/react";

export const Select = defineStyleConfig({
    baseStyle: {
        field: {
            fontFamily: "var(--font-family-main, Inter)",
            fontStyle: "normal",
            fontWeight: 400,
            h: "100%",
            w: "100%",
            cursor: "pointer",
            boxSizing: "border-box",
            borderRadius: "100px",
            border: "2px solid var(--color-border-strong, #959595)",
        },
    },
    variants: {
        default: {
            field: {
                _hover: {
                    _disabled: {
                        bg: "var(--background-disabled, #F1F1F1)",
                    },
                },
                _disabled: {
                    bg: "var(--background-disabled, #F1F1F1)",
                },
            },
        },
    },
    sizes: {
        sm: {
            field: {
                fontSize: "14px",
                height: "32px",
                padding: "8px 14px 8px 20px",
                w: "300px",
            },
        },
        md: {
            field: {
                fontSize: "16px",
                padding: "12px 20px 12px 24px",
                // w: "300px",
                
            },
        },
        lg: {
            field: {
                fontSize: "18px",
                padding: "18px 24px 18px 32px",
                // w: "400px",
            },
        },
    },
    defaultProps: {
        variant: "default",
        size: "md",
    },
});

export const SelectGroup = defineStyleConfig({
    baseStyle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: "100px",
        gap: "8px",
        cursor: "pointer",
        userSelect: "none",
        position: "relative",
    },
    sizes: {
        sm: { w: "300px", h: "36px" },
        md: { w: "400px", h: "48px" },
        lg: { w: "500px", h: "64px" },
    },
    variants: {
        default: {
            border: "2px solid var(--color-border-strong, #959595)",
            bg: "white",
            _hover: {
                border: "2px solid var(--kone-blue, #1450f5)",
                _disabled: {
                    bg: "var(--background-disabled, #F1F1F1)",
                    border: "none",
                },
            },
            _disabled: {
                bg: "var(--background-disabled, #F1F1F1)",
            },
            _invalid: {
                border: "3px solid var(--border-error, #F51414)",
            },
            _focusWithin: {
                border: "3px solid var(--kone-blue, #1450f5)",
            },
        },
        disabled: {
            bg: "var(--background-disabled, #F1F1F1)",
            color: "var(--text-lighter, #727272)",
        },
        invalid: {
            border: "3px solid var(--border-error, #F51414)",
        },
    },
    defaultProps: {
        variant: "default",
        size: "md",
    },
});

export const SelectMenu = defineStyleConfig({
    baseStyle: {
        bg: "var(--bg-primary-800: #ffffff)",
        position: "absolute",
        left: 0,
        padding: "8px",
        boxShadow:
            "0px 3px 5px 0px rgba(17, 22, 26, 0.10), 0px 1px 18px 0px rgba(17, 22, 26, 0.06), 0px 6px 10px 0px rgba(17, 22, 26, 0.08)",
        borderRadius: "16px",
    },
    sizes: {
        sm: { w: "300px", top: "40px" },
        md: { w: "400px", top: "52px" },
        lg: { w: "500px", top: "68px" },
    },
    defaultProps: { variant: "default", size: "md" },
});
