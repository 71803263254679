import { useMutation } from "react-query";

/**
 * API URL
 */
const apiUrl = process.env.REACT_APP_CUSTOMER_DEPLOYMENT_INVOKE_URL;

/**
 * Query names
 */
const queryNames = {
  GETCUSTOMER: "/customer",
  CREATESITE: "/site",
  GETSITE: "/getsite",
  ADDLEVEL: "/level",
  ADDEQUIPMENT: "/equipments",
  SENSOR: "/sensor",
  APPLYSITE: "/apply",
};

export interface SiteDetails {
  name: string;
  type: string;
  description: string;
  is_active: boolean;
}

/**
 * level Object to create levels
 */
export interface LevelInfo {
  name: string;
  index: number;
  description: string;
  coordinate_ui: string;
}

export interface EquipmentInfo {
  name: string;
  type: string;
  group: string;
  description: string;
  number: string;
  model: string;
  rise: string;
  height: string;
  width: string;
  length: string;
}

export interface LineCount {
  equipment_ref: string;
  line_name: string;
  traffic_type: string;
}

export interface SensorInfo {
  type: string;
  manufacturer_name: string;
  manufacturer_id: string;
  device_area: string;
  line_counts: LineCount[];
}

/**
 * @returns url for site location in s3
 * @param token
 * @param customerId
 */
const createSite = async (
  token: string,
  customerId: string,
  siteDetails: SiteDetails
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(siteDetails),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to create site");
  }

  return response.json();
};

/**
 * @param token
 * @param customerId
 * @returns Site id associated with this customer
 */
const getSite = async (token: string, customerId: string) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch site");
  }

  return response.json();
};

/**
 *
 * @param token
 * @param customerId
 * @param siteId
 * @param levelInfo
 * @returns Add a level to the site
 */
const createLevel = async (
  token: string,
  customerId: string,
  siteId: string,
  levelInfo: LevelInfo
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDLEVEL}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(levelInfo),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to create level");
  }

  return response.json();
};

const useCreateLevel = () => {
  return useMutation(
    ({
      token,
      customerId,
      siteId,
      levelInfo,
    }: {
      token: string;
      customerId: string;
      siteId: string;
      levelInfo: LevelInfo;
    }) => createLevel(token, customerId, siteId, levelInfo)
  );
};

/**
 * Get Existing Level Data
 * @param token
 * @param customerId
 * @param siteId
 */
const getLevel = async (token: string, customerId: string, siteId: string) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDLEVEL}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Cannot retrive level information");
  }

  return response.json();
};

/**
 * @param token
 * @param customerId
 * @param siteId
 * @param equipmentId - optional 
 * @returns Equipments Data
 */
const getEquipment = async (
  token: string,
  customerId: string,
  siteId: string,
  equipmentId?: string
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDEQUIPMENT}/${equipmentId? equipmentId : ""}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Cannot retrive equipment information");
  }

  return response.json();
}

/**
 *
 * @param token
 * @param customerId
 * @param siteId
 * @param equipmentInfo
 * @returns Add equipment to the site
 */
const createEquipment = async (
  token: string,
  customerId: string,
  siteId: string,
  equipmentInfo: EquipmentInfo
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDEQUIPMENT}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(equipmentInfo),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to create equipment");
  }

  return response.json();
};

const useCreateEquipment = () => {
  return useMutation(
    ({
      token,
      customerId,
      siteId,
      equipmentInfo,
    }: {
      token: string;
      customerId: string;
      siteId: string;
      equipmentInfo: EquipmentInfo;
    }) => createEquipment(token, customerId, siteId, equipmentInfo)
  );
};

/**
 *
 * @param token
 * @param customerId
 * @param siteId
 * @param levelId
 * @param sensorInfo
 * @returns get sensor on level
 */
const getSensorOnLevel = async (
  token: string,
  customerId: string,
  siteId: string,
  levelId: string,
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDLEVEL}/${levelId}${queryNames.SENSOR}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to add sensor on level ${levelId}`);
  }

  return response.json();
};


/**
 *
 * @param token
 * @param customerId
 * @param siteId
 * @param levelId
 * @param sensorInfo
 * @returns create sensor on level
 */
const createSensorOnLevel = async (
  token: string,
  customerId: string,
  siteId: string,
  levelId: string,
  sensorInfo: SensorInfo
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.ADDLEVEL}/${levelId}${queryNames.SENSOR}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(sensorInfo),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to add sensor on level ${levelId}`);
  }

  return response.json();
};

const useCreateSensorOnLevel = () => {
  return useMutation(
    ({
      token,
      customerId,
      siteId,
      levelId,
      sensorInfo,
    }: {
      token: string;
      customerId: string;
      siteId: string;
      levelId: string;
      sensorInfo: SensorInfo;
    }) => createSensorOnLevel(token, customerId, siteId, levelId, sensorInfo)
  );
};

/**
 *
 * @param token
 * @param customerId
 * @param siteId
 * @returns apply current configuration to the site
 */
const applySite = async (token: string, customerId: string, siteId: string) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CREATESITE}/${siteId}${queryNames.APPLYSITE}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to apply site");
  }

  return response.json();
};

export const SiteAssetApi = {
  queryNames,
  createSite,
  getSite,
  useCreateLevel,
  getLevel,
  getEquipment,
  useCreateEquipment,
  getSensorOnLevel,
  useCreateSensorOnLevel,
  applySite,
};
