// Decode base64 string
const base64Decode = (str: string): string => {
    try {
      return decodeURIComponent(
        atob(str).split('').map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
      );
    } catch (e) {
      console.error('Error decoding base64 string:', e);
      return '';
    }
  };

// Decode JWT token
export const decodeToken = (token: string) => {
  try {
    // Split the token into its parts
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid token");
    }

    // Decode the payload part (second part)
    const header = JSON.parse(base64Decode(parts[0]));
    const payload = JSON.parse(base64Decode(parts[1]));

    return { header, payload };
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};