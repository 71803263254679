import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Input,
  Button,
  Select,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  IconButton,
} from "@chakra-ui/react";
import {
  EquipmentInfo,
  SiteAssetApi,
} from "../../utils/CustomerDeploymentApi/CDSiteAssestApi";
import TrashIcon from "../../assets/icons/trashIcon.svg";
import { useQuery } from "react-query";
import { Equipments, setSiteEquipmentInfo } from "../../features/siteLevel/siteLevelSlice";
import { useAppDispatch } from "../../app/hooks";

interface EquipmentProps {
  id: number;
  equipmentInfo: EquipmentInfo;
}

interface SelectEquipmentProps {
  customerId: string;
  id_token: string;
  siteId: string; //Station Id
}

export const SelectEquipment: React.FC<SelectEquipmentProps> = ({
  customerId,
  id_token,
  siteId,
}) => {
  // Variable's
  const [equipment, setEquipments] = useState<EquipmentProps[]>([
    {
      id: 0,
      equipmentInfo: {
        name: "",
        type: "",
        group: "",
        description: "",
        number: "",
        model: "",
        rise: "",
        height: "",
        width: "",
        length: "",
      },
    },
  ]);
  const [currentEquipment, setCurrentEquipment] = useState<Equipments[]>([]);
  const dispatch = useAppDispatch();

  // Env's
  const createEquipmentMutation = SiteAssetApi.useCreateEquipment();

  // Fetch equipment data from db
  const { data: equipmentData, isFetching: equipmentDataFetching } = useQuery(
    "getEquipments",
    () => SiteAssetApi.getEquipment(id_token, customerId, siteId),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
      // staleTime: 5000,
    }
  );

  // Set equipment when they are fetched
  useEffect(() => {
    dispatch(setSiteEquipmentInfo(currentEquipment));
  }, [currentEquipment]);

  const setEquipmentInfo = (name: string, id: string) => {
    setCurrentEquipment((prev) => [
      ...prev,
      { equipment_name: name, equipment_id: id },
    ]);
  };

  // Set equipment when they are fetched
  useEffect(() => {
    if (equipmentData) {
      const equipments = equipmentData.map((equipment: any, index: number) => {
        setEquipmentInfo(equipment["equipment_name"]["S"], equipment["sk"]["S"]);
        return {
          id: index,
          equipmentInfo: {
            name: equipment["equipment_name"]["S"],
            type: equipment["equipment_type"]["S"],
            group: equipment["equipment_group"]["S"],
            description: equipment["equipment_description"]["S"],
            number: equipment["equipment_number"]["S"],
            model: equipment["equipment_model"]["S"],
            rise: equipment["equipment_rise"]["S"],
            height: equipment["equipment_height"]["S"],
            width: equipment["equipment_width"]["S"],
            length: equipment["equiment_length"]["S"],
          },
        };
      });
      setEquipments(equipments);
    }
  }, [equipmentData]);

  const addequipment = () => {
    setEquipments([
      ...equipment,
      {
        id: equipment.length,
        equipmentInfo: {
          name: "",
          type: "",
          group: "",
          description: "",
          number: "",
          model: "",
          rise: "",
          height: "",
          width: "",
          length: "",
        },
      },
    ]);
  };

  const removeEquipment = (id: number) => {
    alert("Feature Not Developed Yet");
    // if (equipment.length > 1) {
    //   setequipments(equipment.filter((equipment) => equipment.id !== id));
    // }
  };

  const handleInputChange = (
    id: number,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newequipments = equipment.map((equipment) => {
      if (equipment.id === id) {
        return {
          ...equipment,
          equipmentInfo: {
            ...equipment.equipmentInfo,
            [event.target.name]: event.target.value,
          },
        };
      }
      return equipment;
    });
    setEquipments(newequipments);
  };

  // Submit the equipment to the backend
  const handleSubmit = () => {
    equipment.forEach((equipment) => {
      createEquipmentMutation.mutate({
        token: id_token,
        customerId,
        siteId,
        equipmentInfo: equipment.equipmentInfo,
      });
    });
  };

  // If success
  useEffect(() => {
    if (createEquipmentMutation.isSuccess) {
      const returnedData = createEquipmentMutation.data;
      const equipmentId = returnedData["equipment_id"];
      const equipmentName = returnedData["equipment_name"];
      setEquipmentInfo(equipmentName, equipmentId);
    }
  }, [createEquipmentMutation.isSuccess]);

  if (equipmentDataFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Table>
        {/* equipments Section */}
        <Thead>
          <Tr>
            <Td fontWeight="bold">Sr. No</Td>
            <Td fontWeight="bold">Name</Td>
            <Td fontWeight="bold">Description</Td>
            <Td fontWeight="bold">Ken</Td>
            <Td fontWeight="bold">Type</Td>
            <Td fontWeight="bold">Group</Td>
            <Td fontWeight="bold">Model</Td>
            <Td fontWeight="bold">Rise</Td>
            <Td fontWeight="bold">Height</Td>
            <Td fontWeight="bold">Width</Td>
            <Td fontWeight="bold">Length</Td>
            <Td fontWeight="bold"></Td>
          </Tr>
        </Thead>
        <Tbody>
          {equipment.map((equipment, index) => (
            <Tr>
              <React.Fragment key={equipment.id}>
                <Td>{index + 1}</Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Name"
                    value={equipment.equipmentInfo.name}
                    name="name"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Description"
                    value={equipment.equipmentInfo.description}
                    name="description"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="KEN"
                    value={equipment.equipmentInfo.number}
                    name="number"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Select
                    placeholder="Select type"
                    size={"sm"}
                    value={equipment.equipmentInfo.type}
                    name="type"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  >
                    <option value="escalator">Escalator</option>
                    <option value="elevator">Elevator</option>
                    <option value="stairs">Stairs</option>
                  </Select>
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Group"
                    value={equipment.equipmentInfo.group}
                    name="group"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Model"
                    value={equipment.equipmentInfo.model}
                    name="model"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="number"
                    placeholder="Rise"
                    value={equipment.equipmentInfo.rise}
                    name="rise"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="number"
                    placeholder="Height"
                    value={equipment.equipmentInfo.height}
                    name="height"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="number"
                    placeholder="Width"
                    value={equipment.equipmentInfo.width}
                    name="width"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="number"
                    placeholder="Length"
                    value={equipment.equipmentInfo.length}
                    name="length"
                    onChange={(e) => handleInputChange(equipment.id, e)}
                  />
                </Td>
                {/* <Button onClick={() => removeequipment(equipment.id)}>
                  Remove
                </Button> */}
                <Td>
                  <Box
                    bg="white"
                    borderRadius="full"
                    onClick={() => removeEquipment(equipment.id)}
                  >
                    <IconButton
                      aria-label="Delete"
                      icon={<Image src={TrashIcon} boxSize="32px" />}
                      variant="unstyled"
                    />
                  </Box>
                </Td>
              </React.Fragment>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="center" mt={4} gap={4}>
        <Button onClick={addequipment}>Add new equipment</Button>
        <Button onClick={() => handleSubmit()}>Submit</Button>
      </Box>
    </>
  );
};
