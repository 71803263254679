import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Levels {
    level_name: string;
    level_id: string;
}

export interface Equipments {
    equipment_name: string;
    equipment_id: string;
}

interface LevelState {
    levelsInfo: Levels[];
    equipmentsInfo: Equipments[];
}

const initialState: LevelState = {
    levelsInfo: [],
    equipmentsInfo: [],
}

export const LevelSlice = createSlice({
    name: "Level",
    initialState,
    reducers: {
        setSiteLevelInfo: (state, action: PayloadAction<Levels[]>) => {
            state.levelsInfo = action.payload;
        },
        setSiteEquipmentInfo: (state, action: PayloadAction<Equipments[]>) => {
            state.equipmentsInfo = action.payload;
        }
    },
});

export const { setSiteLevelInfo, setSiteEquipmentInfo } = LevelSlice.actions;

export const selectSiteLevelInfo = (state: RootState) => state.siteLevel.levelsInfo;
export const selectSiteEquipmentInfo = (state: RootState) => state.siteLevel.equipmentsInfo;

export default LevelSlice.reducer;