import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectStep,
  setCustomerType,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import "./style.scss";
import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { CustomerType } from "../../features/multiStepForm/customerInfo";
import KoneBrandMark from "../KoneBrandMark";

const MainPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectStep);

  /**
   * Handle the next button click and set the customer type.
   * @New or @Existing
   */
  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value: CustomerType = event.currentTarget.value as CustomerType;
    dispatch(setCustomerType(value));
    dispatch(setStep(step + 1));
  };

  return (
    <Box
      maxW="800px"
      mx="auto"
      my="auto"
      pt="20px"
      px="40px"
      pb="40px"
      borderRadius="24px"
      boxShadow="lg"
      bg="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        alignSelf="flex-start"
        width="86px"
        height="33.49px"
        mb="35px"
        position="relative"
        top="0"
        left="0"
      >
        <KoneBrandMark />
      </Box>
      <Heading
        mb="30px"
        display="flex"
        justifyContent="center"
        fontWeight="400"
      >
        People Flow Digital Customer Deployment Portal
      </Heading>
      <Text mb="10px" color="gray.600">
        Please choose Process to continue
      </Text>
      <VStack spacing="4" maxW="400px" mb="20px">
        <Button
          value="new"
          onClick={(e) => handleNext(e)}
          size="sm"
          w="full"
          fontFamily="font/family/main"
          fontSize="18px"
          fontWeight="400"
        >
          Create new User
        </Button>
        <Button
          value="existing"
          size="sm"
          w="full"
          fontFamily="font/family/main"
          fontSize="18px"
          fontWeight="400"
          onClick={(e) => handleNext(e)}
        >
          Updating existing user
        </Button>
      </VStack>
    </Box>
  );
};
export default MainPage;
