// src/components/MultiStepForm.tsx
import React from "react";
import "./DeploymentForm.scss";
import MainPage from "../MainPage";
import { useAppSelector } from "../../app/hooks";
import { selectCustomerInfo, selectStep } from "../../features/multiStepForm/FormSlice";
import UpdateCustomer from "../UpdateCustomerPage/UpdateCustomer";
import { CustomerOnboard } from "../CustomerOnboardPage/CustomerOnboard";
import { Box } from "@chakra-ui/react";
import { CustomerInfoPage } from "../CustomerInfo";
import { MetroLines } from "../MetroLines";
import { StationMetaDataPage } from "../StationMetaDataPage";
import { StationInfoPage } from "../StationInfoPage";
import { CustomerType } from "../../features/multiStepForm/customerInfo";

const DeploymentForm: React.FC = () => {
  const step = useAppSelector(selectStep);
  const customerInfo = useAppSelector(selectCustomerInfo);

  return (
    <Box
      backgroundColor="var(--color-brand-light-hover, #F3F6FE);"
      display="flex"
      flexDirection="column"
      p="20px"
      margin="10px"
      height="90%"
      overflow="auto"
    >
      {step === 1 && <MainPage />}
      {step === 2 && customerInfo.customerType === CustomerType.EXISTING && (
        <UpdateCustomer />
      )}
      {step === 2 && customerInfo.customerType === CustomerType.NEW && (
        <CustomerOnboard />
      )}
      {step === 3 && <CustomerInfoPage />}
      {/* {step === 4 && <MetroLines />} */}
      {step === 4 && <StationMetaDataPage />}
      {step === 5 && <StationInfoPage />}
    </Box>
  );
};

export default DeploymentForm;
