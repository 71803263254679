import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Tokens {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

export interface User {
  userName: string;
  userEmail: string;
}
interface AuthState {
  loggedIn: boolean;
  status: StateStatus;
  tokens: Tokens | null;
  user: User | undefined;
}

export enum StateStatus {
  IDLE = "idle",
  PENDING = "pending",
  FAILED = "failed",
}

const initialState: AuthState = {
  loggedIn: false,
  status: StateStatus.IDLE,
  tokens: null,
  user: undefined,
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case StateStatus.PENDING:
          state.status = StateStatus.PENDING;
          break;
        case StateStatus.IDLE:
          state.status = StateStatus.IDLE;
          break;
        case StateStatus.FAILED:
          state.status = StateStatus.FAILED;
          break;
      }
    },
    setTokens: (state, action: PayloadAction<Tokens>) => {
      // Initialize state.tokens if it's null
      if (!state.tokens) {
        state.tokens = {
          access_token: "",
          id_token: "",
          refresh_token: "",
        };
      }
      state.tokens.access_token = action.payload.access_token;
      state.tokens.id_token = action.payload.id_token;
      state.tokens.refresh_token = action.payload.refresh_token;
    },
    setUserDetails: (state, action: PayloadAction<User>) => {
      if (!state.user) {
        state.user = {
          userEmail: "--",
          userName: "--",
        };
      }
      state.user.userEmail = action.payload.userEmail;
      state.user.userName = action.payload.userName;
    },
  },
});

export const { setLoggedIn, setStatus, setTokens, setUserDetails } =
  AuthSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
export const selectAuthTokens = (state: RootState) => state.auth.tokens;
export const selectUser = (state: RootState) => state.auth.user;

export default AuthSlice.reducer;
