import axios, { AxiosRequestConfig } from "axios";
import queryString from "query-string";

export interface RequestOptions {
  body?: any;
  queryParams?: any;
  customHeaders?: any;
  isFile?: boolean;
  withCredentials?: boolean;
  shouldRetry?: boolean;
  formEncoded?: boolean;
  unauthorize?: boolean;
  disableLoader?: boolean;
}

const getHeaders = (options: RequestOptions) => {
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "If-Modified-Since": "0",
  };

  if (options.customHeaders) {
    Object.assign(headers, options.customHeaders);
  }

  return headers;
};

const getUrl = (endpoint: string, options: RequestOptions): string => {
  let url = endpoint;

  if (!url) {
    throw new Error(`Endpoint url not found: ${endpoint}`);
  }

  if (options.queryParams) {
    url += decodeURIComponent(`?${queryString.stringify(options.queryParams)}`);
  }

  return url;
};

const fetchAuthToken = async (code: string) => {
  const redirectUri = `${window.location.origin}${process.env.REACT_APP_LOGIN_REDIRECT_URI}`;

  const options: RequestOptions = {
    body: {
      code,
      redirectUri,
    },
    customHeaders: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    formEncoded: true,
  };

  //   Construct Url if there are any query params present
  const constructedUrl = getUrl(
    process.env.REACT_APP_AUTH_URL_ENDPOINT_TOKEN!,
    options
  );
  //   Config for axios to fetch token
  const requestConfig: AxiosRequestConfig = {
    url: constructedUrl,
    baseURL: process.env.REACT_APP_AUTH_URL,
    headers: getHeaders(options),
    method: "POST",
    responseType: "json",
    timeout: 30 * 1000,
    withCredentials: false,
    data: options.body,
  };

  try {
    const response = await axios(requestConfig);
    console.info("Received Token Sucessfully!")
    return response;
  } catch (e) {
    console.error("Failed to fetch token", e);
  }
};

export default fetchAuthToken;
