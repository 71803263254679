import React from "react";
import KoneBrandMark from "../KoneBrandMark";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { RiLogoutBoxLine } from "react-icons/ri";
import { User, selectUser } from "../../features/authorization/AuthSlice";
import { useAppSelector } from "../../app/hooks";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import { useLocation } from "wouter";

export const TopNavBar: React.FC = () => {
  const [, setLocation] = useLocation();
  const userDetails: User | undefined = useAppSelector(selectUser);

  const logout = () => {
    // window.sessionStorage.clear();
    setLocation("/login");
    // console.log("clicked logout");
  };

  return (
    <Flex
      id="top-navBar"
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      h="8%"
      bgColor="var(--color-surface-surface_bg, #FFFFFF);"
      borderBottom="1px solid var(--color-grey-400, #D0D0D0)"
      pe="30px"
      position="sticky"
      top={0}
    >
      <Flex
        alignItems="center"
        fontSize="18px"
        fontWeight="400"
        lineHeight="24px"
        fontFamily="font/family/main"
      >
        <KoneBrandMark />
        Customer Deployment Portal
      </Flex>
      <Flex gap="8px">
        <Flex
          alignItems="center"
          justifyContent="center"
          w="28px"
          h="28px"
          bgColor="var(--blue-light-40, #4373f7)"
          borderRadius="100px"
        >
          <UserIcon
            fill="var(--bg-primary-800, #ffffff)"
            width="20px"
            height="20px"
          />
          <div className="user-status active"></div>
        </Flex>
        <Menu>
          <MenuButton id="user-info-menu-button">
            <Flex alignItems="center" justifyContent="center" gap="4px">
              <Box>{userDetails ? userDetails?.userEmail : "--"}</Box>
              <ChevronDown width="20px" height="20px" />
            </Flex>
          </MenuButton>
          <MenuList minW="240px" bgColor="var(--bg-primary-800, #ffffff)">
            <MenuItem
              _hover={{ bgColor: "var(--blue-light-90)" }}
              gap="8px"
              onClick={logout}
              id="logout-button"
            >
              <RiLogoutBoxLine fill="var(--kone-blue)" />
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
