import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UIInstanceType } from "./FormTypes";

type FormState = {
    UIInstance: UIInstanceType;
};

const initialState: FormState = {
    UIInstance: undefined,
};

export const FormSlice = createSlice({
    name: "formState",
    initialState,
    reducers: {
        setUIInstance: (state, action: PayloadAction<UIInstanceType>) => {
            state.UIInstance = action.payload;
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setUIInstance } = FormSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectUIInstance = (state: RootState) =>
    state.formState.UIInstance;

export default FormSlice.reducer;
