import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectCustomerInfo,
  selectStep,
  setSiteInfo,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import {
  Box,
  Button,
  HStack,
  Input,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  ListItemProps,
  Stack,
} from "@chakra-ui/react";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import { ListItem as ChakraListItem } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { SiteAssetApi } from "../../utils/CustomerDeploymentApi/CDSiteAssestApi";
import { set } from "react-hook-form";
import { BackButton } from "../BackButtonWitHeading/BackButtonWitHeading";

interface StationList {
  site_id: string;
  site_name: string;
  site_type: string;
  site_is_active?: boolean;
}

export const StationMetaDataPage: React.FC = () => {
  // Env's
  const step = useAppSelector(selectStep);
  const tokens = useAppSelector(selectAuthTokens);
  const dispatch = useAppDispatch();
  const customerInfo = useAppSelector(selectCustomerInfo);

  // Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [stations, setStations] = useState<StationList[]>([]);
  const [selectedStation, setSelectedStation] = useState<any>();
  const [filteredStations, setFilteredStations] = useState<StationList[]>([]);
  const [activeSite, setActiveSite] = useState<boolean>(false);
  // Api Call to import station metadata
  const { data, isFetching, isSuccess } = useQuery(
    SiteAssetApi.queryNames.GETSITE,
    () =>
      SiteAssetApi.getSite(
        tokens!.id_token,
        customerInfo.customerDetails.customerId
      ),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
      staleTime: 5000,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setStations([]);
      data.map((site: any) => {
        setStations((prev) => [
          ...prev,
          {
            site_id: site.sk["S"],
            site_name: site.site_name["S"],
            site_type: site.site_type["S"],
            site_is_active: site.site_is_active["BOOL"],
          },
        ]);
      });
    }
  }, [isSuccess]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleNext = () => {
    dispatch(setStep(step + 1));
  };

  const handleSubmit = () => {
    if (selectedStation) {
      dispatch(setSiteInfo(selectedStation));
      handleNext();
    } else {
      alert("Please select a station");
    }
  };

  useEffect(() => {
    setFilteredStations(
      stations.filter((station: any) => {
        return station.site_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      })
    );
  }, [searchQuery, stations]);

  useEffect(() => {
    if (activeSite) {
      setFilteredStations(
        stations.filter((station: any) => {
          return station.site_is_active === true;
        })
      );
    } else {
      setFilteredStations(stations);
    }
  }, [activeSite]);

  const handleSelect = (station: any) => {
    if (selectedStation?.site_id === station.site_id) {
      setSelectedStation(undefined); // Deselect if already selected
      setFilteredStations(stations.filter((station: any) => {
        return station.site_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      })); // Reset the filter
    } else {
      setSelectedStation(station); // Select the station
      setFilteredStations([station]); // Filter the selected station
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BackButton displayName="Station Metadata" />
      <HStack mb={5}>
        <Input
          type="text"
          placeholder="Search stations..."
          onChange={(e) => handleSearch(e)}
        />
        <Button
        width={"120px"}
          onClick={() =>
            activeSite ? setActiveSite(false) : setActiveSite(true)
          }
        >
          {activeSite ? "All Sites" : "Active Site"}
        </Button>
        <Button type="submit" onClick={handleSubmit}>
          Submit
        </Button>
      </HStack>
      <Box borderBottom="2px" borderColor="gray.300" mb={5} />
      <Table variant="simple">
        <Thead>
          <Tr
            style={{
              fontFamily: "var(--fontfamilymain)",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            <Td>Site name</Td>
            <Td>Site type</Td>
            <Td>Site id</Td>
            <Td />
          </Tr>
        </Thead>
        <Tbody>
          {filteredStations.map((station: any, index: number) => {
            const selected = selectedStation?.site_id === station.site_id;
            return (
              <Tr
                key={index}
                bg={selected ? "gray.300" : "white"}
                mb={1}
                borderBottom={"1px solid var(--color-border-normal, #E7E7E7)"}
                className="customer-row"
              >
                <Td>{station.site_name}</Td>
                <Td>{station.site_type}</Td>
                <Td>{station.site_id}</Td>
                <Td>
                  <Button onClick={() => handleSelect(station)} width={"100px"}>
                    {selected ? "Deselect" : "Select"}
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* <Button type="submit" onClick={handleNext} mt={5} isDisabled>
        Skip
      </Button> */}
    </>
  );

  // return (
  //   <>
  //     <Box>
  //       <b>Station MetaData: {customerInfo.customerId}</b>
  //     </Box>
  //     <Stack direction="column">
  //       <span>Select and upload station metadata files</span>
  //       <RadioGroup onChange={setValue} value={value}>
  //         <Stack direction="row">
  //           <Radio
  //             value="b1"
  //             size="lg"
  //             colorScheme="blue"
  //             borderRadius="md"
  //             px={4}
  //             py={2}
  //           >
  //             M1
  //           </Radio>
  //           <Radio
  //             value="b2"
  //             size="lg"
  //             colorScheme="blue"
  //             borderRadius="md"
  //             px={4}
  //             py={2}
  //           >
  //             M2
  //           </Radio>
  //         </Stack>
  //       </RadioGroup>
  //     </Stack>
  //     <Box>
  //       <Grid templateColumns="repeat(7, 1fr)">
  //         <Text>Station name</Text>
  //         <Text>GTFS Parent station</Text>
  //         <Text>Lines</Text>
  //         <Text>Lon</Text>
  //         <Text>Lat</Text>
  //         <Text>File Link</Text>
  //         <Text>Applied UI configuration</Text>

  //         {/* Row 1 */}
  //         <Input placeholder="Give name" />
  //         <Text>10000034 - Kivenlahti</Text>
  //         <Text>M1</Text>
  //         <Text>23.232</Text>
  //         <Text>61.345</Text>
  //         <Select placeholder="s3://dt/.../KVL-json.ld">
  //           {/* Add options */}
  //         </Select>
  //         <Select placeholder="Select one">
  //           {/* Add options  */}
  //         </Select>

  //         {/* Row 2 */}
  //         <Input placeholder="Give name" />
  //         <Text>10000035 - Espoonlahti</Text>
  //         <Text>M1</Text>
  //         <Text>22.872</Text>
  //         <Text>61.245</Text>
  //         <Select placeholder="s3://dt/.../ESP-json.ld">
  //           {/* Add options if needed */}
  //         </Select>
  //         <Select placeholder="Select one">
  //           {/* Add options if needed */}
  //         </Select>

  //       </Grid>
  //     </Box>
  //     <Button type="submit">Submit</Button>
  //     <Button type="button" onClick={onSkip}>Go to Station Configuration</Button>
  //   </>
  // );
};

const ListItem: React.FC<ListItemProps> = (props) => {
  return (
    <ChakraListItem
      m="12px 0"
      display="flex"
      gap="60px"
      justifyContent="flex-start"
      {...props}
    ></ChakraListItem>
  );
};
