import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  CustomerDetails,
  CustomerInfo,
  CustomerType,
  SiteInfo,
} from "./customerInfo";

interface FormState {
  step: number;
  customerInfo: CustomerInfo;
}

const initialState: FormState = {
  step: 1,
  customerInfo: {
    customerDetails: {
      customerName: "",
      customerSalesforceId: "",
      customerId: "",
      hasSimulation: false,
      instanceType: undefined,
    },
    customerType: CustomerType.EXISTING,
    assetId: "",
    siteInfo: undefined,
    siteId: "",
  },
};

export const FormSlice = createSlice({
  name: "Form",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setCustomerType: (state, action: PayloadAction<CustomerType>) => {
      state.customerInfo.customerType = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerInfo.customerDetails.customerId = action.payload;
    },
    setCustomerDetails: (state, action: PayloadAction<CustomerDetails>) => {
      state.customerInfo.customerDetails = action.payload;
    },
    setAssetId: (state, action: PayloadAction<string>) => {
      state.customerInfo.assetId = action.payload;
    },
    setSiteInfo: (state, action: PayloadAction<SiteInfo>) => {
      state.customerInfo.siteInfo = action.payload;
    },
    setSiteId: (state, action: PayloadAction<string>) => {
      state.customerInfo.siteId = action.payload;
    },
  },
});

/*===============================
            Actions
==============================*/

export const {
  setStep,
  setCustomerType,
  setAssetId,
  setSiteInfo,
  setSiteId,
  setCustomerDetails,
  setCustomerId,
} = FormSlice.actions;

/*===============================
           Selectors
==============================*/

export const selectStep = (state: RootState) => state.multiForm.step;
export const selectCustomerInfo = (state: RootState) =>
  state.multiForm.customerInfo;

export default FormSlice.reducer;
