import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
    baseStyle: {
        cursor: "pointer",
        userSelect: "none",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        fontFamily:
            "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        borderRadius: "100px",
    },
    sizes: {
        xs: {
            height: "32px",
            padding: "8px 20px",
            fontSize: "14px",
        },
        sm: {
            height: "32px",
            padding: "8px 22px",
            fontSize: "16px",
        },
        md: {
            height: "48px",
            padding: "8px 24px",
            fontSize: "18px",
        },
        lg: {
            height: "64px",
            padding: "8px 30px",
            fontSize: "20px",
        },
        xl: {
            height: "72px",
            padding: "8px 34px",
            fontSize: "32px",
        },
    },
    variants: {
        default: {
            bg: "var(--kone-blue, #1450f5)",
            color: "white",
            _disabled: {
                bg: "var(--blue-300, #7296f9)",
            },
            _hover: {
                bg: "var(--blue-400, #4373f7)",
                _disabled: {
                    bg: "var(--blue-300, #7296f9)",
                },
            },
        },
        secondary: {
            bg: "var(--blue-50, #f3f6fe)",
            color: "var(--kone-blue, #1450f5)",
            _hover: {
                border: "3px solid var(--kone-blue, #1450f5)",
            },
            _disabled: {
                color: "var(--blue-300, #7296f9)",
            },
        },
        selectButton: {
            fontFamily: "var(--font-family-main, Inter)",
            fontStyle: "normal",
            fontWeight: 400,
            h: "auto",
            margin: 0,
            fontSize: "inherit",
            w: "auto",
            bg: "transparent",
            flexGrow: 1,
            padding: 0,
            justifyContent: "flex-start",
        },
    },
    defaultProps: {
        size: "sm",
        variant: "default",
    },
});
