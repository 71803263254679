import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Th,
} from "@chakra-ui/react";
import {
  LevelInfo,
  SiteAssetApi,
} from "../../utils/CustomerDeploymentApi/CDSiteAssestApi";
import {
  LevelAssetsDetails,
  uploadAssestToLevel,
} from "../../utils/CustomerDeploymentApi/UploadAssetsToLevel";
import { useQuery } from "react-query";
import { useAppDispatch } from "../../app/hooks";
import { Levels, setSiteLevelInfo } from "../../features/siteLevel/siteLevelSlice";

/**
 * Level Props
 */
interface LevelProps {
  id: number;
  levelInfo: LevelInfo;
  file: File | null;
}
/**
 * Station Levels Props
 */
interface StationLevelsProps {
  customerId: string;
  id_token: string;
  siteId: string;
}

export const StationLevels: React.FC<StationLevelsProps> = ({
  customerId,
  id_token,
  siteId,
}) => {
  /**
   * Empty Level Object
   */
  const emptyLevel: LevelInfo = {
    index: 0,
    name: "",
    description: "",
    coordinate_ui: "",
  };

  // Env's
  const createLevelMutation = SiteAssetApi.useCreateLevel();

  // Variables
  const [levels, setLevels] = useState<LevelProps[]>([
    { id: 0, levelInfo: emptyLevel, file: null },
  ]);
  const dispatch = useAppDispatch();
  const [currentLevelInfo, setCurrentLevelInfo] = useState<Levels[]>([]);


  // Api Call to get levels if exists
  // TODO: Add the api call to get levels
  const { data: levelsData, isFetching: levelDataFetching } = useQuery(
    "GetLevels",
    () => SiteAssetApi.getLevel(id_token, customerId, siteId),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
      // staleTime: 5000,
    }
  );

  useEffect(() => {
    dispatch(setSiteLevelInfo(currentLevelInfo));
  }, [currentLevelInfo]);

  // Set the levels if data is fetched
  useEffect(() => {
    if (levelsData) {
      const levels = levelsData.map((level: any, index: number) => {
        setLevelInfo(level["level_name"]["S"], level["sk"]["S"]);
        return {
          id: index,
          levelInfo: {
            index: level["level_index"]["N"],
            name: level["level_name"]["S"],
            description: level["level_description"]["S"],
            coordinate_ui: level["level_coordinate_ui"]["S"],
          },
          file: null,
        };
      });
      setLevels(levels);
    }
  }, [levelsData]);

  const addLevel = () => {
    setLevels([
      ...levels,
      {
        id: levels.length,
        levelInfo: emptyLevel,
        file: null,
      },
    ]);
  };

  // Function to set the level info for sensor data
  const setLevelInfo = (name: string, id: string) => {
    setCurrentLevelInfo((prev) => [...prev, { level_name: name, level_id: id }]);
  };

  // const removeLevel = (id: number) => {
  //   if (levels.length > 1) {
  //     setLevels(levels.filter((level) => level.id !== id));
  //   }
  // };

  /**
   * Add level info to the levels state
   * @param id
   * @param event
   */
  const handleInputChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLevels = levels.map((level) => {
      if (level.id === id) {
        if (event.target.name === "file") {
          return { ...level, file: event.target.files?.[0] || null };
        } else {
          return {
            ...level,
            levelInfo: {
              ...level.levelInfo,
              [event.target.name]: event.target.value,
            },
          };
        }
      }
      return level;
    });
    setLevels(newLevels);
  };

  // Upload file to S3
  const handleUploadFile = async (levelId: string, levelName: string) => {
    const selectedLevel = levels.find(
      (level) => level.levelInfo.name === levelName
    );
    const assetDetails: LevelAssetsDetails = {
      name: selectedLevel?.file?.name || "No File",
      type: "gltf",
      description: `GLTF for ${levelName}`,
    };
    if (selectedLevel?.file) {
      console.log("Uploading file...");
      await uploadAssestToLevel(
        id_token,
        customerId,
        siteId,
        levelId,
        assetDetails,
        selectedLevel.file
      );
    } else {
      console.log("No file selected");
    }
  };

  /**
   * Submit the levels to the backend
   */
  const handleSubmit = (id: number) => {
    levels.forEach((level) => {
      if (level.id === id) {
        if (level.file === null) {
          alert(`Please select a file to upload the level`);
        }
        createLevelMutation.mutate({
          token: id_token,
          customerId,
          siteId,
          levelInfo: level.levelInfo,
        });
      }
    });
  };

  useEffect(() => {
    if (createLevelMutation.isSuccess) {
      const returnedData = createLevelMutation.data; // Parse the returned JSON data
      const levelId = returnedData.level_id; // Extract level_id
      const levelName = returnedData.name; // Extract level name
      handleUploadFile(levelId, levelName);
      setLevelInfo(levelName, levelId);
    }
  }, [createLevelMutation.isSuccess]);

  if (levelDataFetching) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Sr No.</Th>
            <Th>Level Index</Th>
            <Th>Level Name</Th>
            <Th>Level Description</Th>
            <Th>Co-ordinate Ui</Th>
            <Th>Add File</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {levels.map((level, index) => (
            <Tr>
              <React.Fragment key={level.id}>
                <Td>{index + 1}</Td>
                <Td>
                  <Input
                    type="Number"
                    placeholder="Add Level Index"
                    value={level.levelInfo.index}
                    name="index"
                    onChange={(e) => handleInputChange(level.id, e)}
                    bg="white"
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Name for Level"
                    value={level.levelInfo.name}
                    name="name"
                    onChange={(e) => handleInputChange(level.id, e)}
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Description"
                    value={level.levelInfo.description}
                    name="description"
                    onChange={(e) => handleInputChange(level.id, e)}
                    bg="white"
                  />
                </Td>
                <Td>
                  <Input
                    type="text"
                    placeholder="Co-ordinated Ui"
                    value={level.levelInfo.coordinate_ui}
                    name="coordinate_ui"
                    onChange={(e) => handleInputChange(level.id, e)}
                    bg="white"
                  />
                </Td>
                <Td>
                  <Box
                    width="300px"
                    display="flex"
                    alignItems="center"
                    outline="none"
                    border="2px solid var(--color-border-strong, #959595)"
                    borderRadius="100px"
                    background="white"
                    p="2"
                    gap="4"
                    height={"32px"}
                  >
                    <Text color="gray.500" w="200px">
                      {level.file ? level.file?.name : "No file selected"}
                    </Text>
                    <Button
                      colorScheme="blue"
                      height={"24px"}
                      fontSize={"12px"}
                      as={"label"}
                      htmlFor={`${level.id}fileInput`}
                      // onClick={() =>
                      //   document.getElementById("fileInput")?.click()
                      // }
                    >
                      Choose file
                    </Button>
                    <Input
                      id={`${level.id}fileInput`}
                      type="file"
                      display="none"
                      name="file"
                      onChange={(e) => handleInputChange(level.id, e)}
                      accept=".gltf"
                    />
                  </Box>
                </Td>
                <Td>
                  {/* <Button onClick={() => removeLevel(level.id)}>Remove</Button> */}
                </Td>
                <Td>
                  <Button onClick={() => handleSubmit(level.id)}>Upload</Button>
                </Td>
              </React.Fragment>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="center" mt={4} gap={4}>
        <Button onClick={addLevel}>Add Level</Button>
        <Button>Continue</Button>
      </Box>
    </>
  );
};
