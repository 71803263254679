interface UploadUrl {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    "x-amz-security-token": string;
    policy: string;
    signature: string;
  };
}

interface UploadData {
  id: string; // asset id
  name: string;
  type: string;
  description: string;
  upload_url: UploadUrl;
}

export interface CustomerAssetsDetails {
  /**
   * Name of asset/FILE
   */
  name: string;
  /**
   * Type of asset/FILE
   */
  type: string;
  /**
   * Description of asset/FILE
   */
  description: string;
}

/**
 * API URL
 */
const apiUrl = process.env.REACT_APP_CUSTOMER_DEPLOYMENT_INVOKE_URL;

/**
 * Query names
 */
const queryNames = {
  GETCUSTOMER: "/customer",
  GETCUSTOMERASSETS: "/getassets",
  CUSTOMERASSETS: "/assets",
  IMPORTSTATION: "/import",
};

/**
 * @returns url for customer assets location in s3
 * @param customerId
 * @param token
 * @param customerAssetDetails - name, type, description
 */
const createCustomerAssests = async (
  token: string,
  customerId: string,
  customerAssetDetails: CustomerAssetsDetails
) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CUSTOMERASSETS}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(customerAssetDetails),
    }
  );
  if (!response.ok) {
    const errorText = await response.json();
    throw new Error(`Error: ${errorText.message}`);
  }

  return response.json();
};

/**
 * Get customer assets
 * @param token
 * @param customerId
 */
const getCustomerAssets = async (token: string, customerId: string) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CUSTOMERASSETS}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorText = await response.json();
    throw new Error(`Error: ${errorText.message}`);
  }

  return response.json();
};

/**
 * Upload file to s3
 * @param file
 * @param customerAssetDetails
 * @param token
 * @param customerId
 */
const uploadFileToS3 = async (
  file: File,
  customerAssetDetails: CustomerAssetsDetails,
  token: string,
  customerId: string
) => {
  const formData = new FormData();

  //  get the url and fields for uploading the file
  const data: UploadData = await createCustomerAssests(
    token,
    customerId,
    customerAssetDetails
  );

  const url = data["upload_url"]["url"];
  Object.entries(data["upload_url"]["fields"]).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append("file", file);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("File uploaded successfully");
      await importStations(token, customerId, data.id);
      console.log("Stations imported successfully");
    } else {
      console.error("File upload failed", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading file", error);
  }
};


/**
 * Import all stations name 
 */
const importStations = async (token: string, customerId: string, assetId: string) => {
  const response = await fetch(`${apiUrl}${queryNames.GETCUSTOMER}/${customerId}${queryNames.CUSTOMERASSETS}/${assetId}${queryNames.IMPORTSTATION}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  
  if(!response.ok) {
    const errorText = await response.json();
    throw new Error(`Error: ${errorText.message}`);
  }

  return response.json();
}
export const CustomerAssetsApi = {
  queryNames,
  createCustomerAssests,
  getCustomerAssets,
  uploadFileToS3,
  importStations,
};
