import { UIInstanceType } from "../form/FormTypes";

/**
 * Type of Cutomer (New or Existing)
 */
export enum CustomerType {
  NEW = "new",
  EXISTING = "existing",
}

/**
 * Station information for the customer
 */
export interface SiteInfo {
  site_description: string;
  site_type: string;
  site_name: string;
  site_id: string;
}


/**
 * Customer Info Form Values
 */
export interface CustomerInfoFormValues {
  stationsNetwork: {
    filetype: string;
    files: File | null;
    description: string;
  };
}

export interface CustomerDetails {
    /**
   * Customer Name
   * @type {string}
   */
    customerName: string;
    /**
     * Customer Id
     * @type {string}
     */
    customerId: string;
    /**
     * Customer Salesforce Id
     * @type {string}
     */
    customerSalesforceId: string;
    /**
     * Has Simulation
     */
    hasSimulation: boolean;
    /**
     * Customer Type (METRO_LINE)
     * @type {UIInstanceType}
     */
    instanceType: UIInstanceType;
}

/**
 * Customer Info Skeleton
 */
export interface CustomerInfo {
  /**
   * Customer Details
   */
  customerDetails: CustomerDetails;
  /**
     * Customer Type (New or Existing)
     * @type {CustomerType}
     */
  customerType: CustomerType;
  /**
   * Customer Id
   * @type {string}
   */
  assetId: string;
  /**
   * Site Info
   * @type {SiteInfo}
   */
  siteInfo: SiteInfo | undefined;
  /**
   * Site Id (if exists)
   */
  siteId: string;
  // metroAPI: {
  //   metroAPIType: string;
  //   metroAPIurl: string;
  // };
  // weatherApi: {
  //   weatherApiType: string;
  //   weatherApiUrl: string;
  // };
  // topologyStandard: string;
}
