import { extendTheme } from "@chakra-ui/react";
import {
    Button,
    Input,
    Select,
    InputGroup,
    SelectGroup,
    SelectMenu,
} from "./chakraComponents";

export const theme = extendTheme({
    styles: {
        global: {
            body: {
                // color: "white",
                // bg: "darkBackgroundSecondary",
                fontFamily:
                    "'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "medium",
                fontWeight: 400,
            },
        },
    },
    colors: {
        koneBlue: "#1450f5",

        blue600: "#1444c8",
        blue400: "#4373f7",
        blue300: "#7296f9",
        blue70: "#e7edfe",

        grey500: "#959595",

        surfaceBlueSecondary: "#f3f6fe",

        greenBase: "#1ed273",
        redBase: "#ff5f28",

        darkBackgroundPrimary: "#11161a",
        darkBackgroundSecondary: "#1c2226",

        darkGrayscale200: "#aaafb3",
        darkGrayscale400: "#262e33",
        darkGrayscale600: "#21242d",
        darkGrayscale700: "#1b1e26",
        darkGrayscale800: "#141414",
    },
    components: {
        Input,
        Button,
        InputGroup,
        Select,
        SelectGroup,
        SelectMenu,
    },
    CSS: {
        ".chakra-select__wrapper": {
            margin: `0 !important`,
        },
    },
});
