import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Tokens,
  User,
  selectLoggedIn,
  setLoggedIn,
  setTokens,
  setUserDetails,
} from "../../features/authorization/AuthSlice";
import { Redirect } from "wouter";
import fetchAuthToken from "../../middleware/fetchAuthToken";
import { decodeToken } from "../../utils/jwt/decodeJWT";

const CallbackComponent: React.FC = () => (
  <div>
    <h1>Loading.....</h1>
  </div>
);

const LoginCallback: React.FC = () => {
  // Catch Parameter's from Url
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const dispatch = useAppDispatch();
  const authenticated = useAppSelector(selectLoggedIn);

  useEffect(() => {
    if (code) {
      const fetchTokens = async () => {
        try {
          // Fetch JWT Token using auth code
          const response = await fetchAuthToken(code);
          const tokens: Tokens = {
            id_token: response?.data.id_token,
            access_token: response?.data.access_token,
            refresh_token: response?.data.refresh_token,
          };

          // Decode Token for User Details
          const decodedToken = decodeToken(tokens.id_token);
          const userDetails: User = {
            userEmail: decodedToken!.payload["email"],
            userName: decodedToken!.payload["cognito:username"],
          };

          // Dispatch Tokens and UserDetail and Login Info
          console.log("Tokens:", tokens);
          dispatch(setTokens(tokens));
          dispatch(setUserDetails(userDetails));
          dispatch(setLoggedIn(true));
          setInterval(() => dispatch(setLoggedIn(false)), 3600000);
        } catch (error) {
          console.error("Failed to fetch auth tokens:", error);
        }
      };

      fetchTokens();
    }
  }, [code, dispatch]);

  if (authenticated) {
    return <Redirect to="/multi-form"/>;
  }

  return <CallbackComponent />;
};

export default LoginCallback;
