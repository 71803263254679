import React from "react";
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import leftArrow from "../../assets/icons/leftArrow.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectStep, setStep } from "../../features/multiStepForm/FormSlice";

interface BackButtonProps {
  displayName: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ displayName }) => {
  const step = useAppSelector(selectStep);

  const dispatch = useAppDispatch();

  const handlePrevious = () => {
    // handle back button click
    dispatch(setStep(step - 1));
  };
  return (
    <HStack mb={10}>
      <Box as="button" onClick={handlePrevious}>
        <Image src={leftArrow} alt="back-button" marginRight="20px" />
      </Box>
      <Text
        fontFamily="font/family/main"
        fontSize="32px"
        lineHeight="48px"
        fontWeight="400"
      >
        {displayName}
      </Text>
    </HStack>
  );
};
