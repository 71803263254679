import React, { useEffect, useState } from "react";
import "./UpdateCustomer.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectStep,
  setCustomerDetails,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import { useQuery } from "react-query";
import { CustomerDeploymentApi } from "../../utils/CustomerDeploymentApi/CustomerDeploymentApi";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import {
  Box,
  Button,
  HStack,
  Input,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { CustomerDetails } from "../../features/multiStepForm/customerInfo";
import { BackButton } from "../BackButtonWitHeading/BackButtonWitHeading";

const UpdateCustomer: React.FC = () => {
  // Variables
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [filteredCustomerData, setFilteredCustomerData] = useState<[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails>();

  // Env's
  const step = useAppSelector(selectStep);
  const tokens = useAppSelector(selectAuthTokens);
  const dispatch = useAppDispatch();

  // Api Call on page load
  const {
    data: customerData = [],
    isLoading,
    isFetched,
  } = useQuery(
    CustomerDeploymentApi.queryNames.GETCUSTOMER,
    () => CustomerDeploymentApi.getCustomerDetailsById(tokens!.id_token),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
    }
  );

  //Filter the data according to filter
  useEffect(() => {
    const filteredData = customerData.filter((customer: any) => {
      return customer["customer_name"]["S"]
        .toLowerCase()
        .includes(searchCustomer.toLowerCase());
    });
    setFilteredCustomerData(filteredData);
  }, [searchCustomer, isFetched]);

  // Set the selected customer
  useEffect(() => {
    if (selectedCustomer) {
      dispatch(setCustomerDetails(selectedCustomer));
    }
  }, [selectedCustomer]);

  // Function to select a customer
  const selectCustomer = (customer: any) => {
    if (isSelected) {
      setSelectedCustomer(undefined);
      setIsSelected(false);
    } else {
      setSelectedCustomer({
        customerName: customer["customer_name"]["S"],
        customerId: customer["sk"]["S"],
        customerSalesforceId: customer["customer_salesforce_id"]["S"],
        hasSimulation: customer["customer_has_simulation"]["BOOL"],
        instanceType: customer["customer_type"]["S"],
      });
      setIsSelected(true);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <BackButton displayName="Select Customer" />
      <HStack mb={5}>
        <Input
          placeholder="Search Customer"
          onChange={(e) => setSearchCustomer(e.target.value)}
        />
        <Button
          isDisabled={!isSelected}
          onClick={() => dispatch(setStep(step + 1))}
        >
          Next
        </Button>
      </HStack>
      <Box borderBottom="2px" borderColor="gray.300" mb={5} />
      <Table variant="simple">
        <Thead>
          <Tr
            style={{
              fontFamily: "var(--fontfamilymain)",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            <Td>Customer Name</Td>
            <Td>Instance Type</Td>
            <Td>Description</Td>
            <Td>Salesforce Id</Td>
            <Td />
          </Tr>
        </Thead>
        <Tbody>
          {filteredCustomerData.map((customer: any, index: number) => {
            const selected =
              selectedCustomer?.customerSalesforceId ===
              customer["customer_salesforce_id"]["S"];
            return (
              <Tr
                key={index}
                bg={selected ? "gray.300" : "white"}
                mb={1}
                borderBottom={"1px solid var(--color-border-normal, #E7E7E7)"}
                className="customer-row"
              >
                <Td>{customer["customer_name"]["S"]}</Td>
                <Td>{customer["customer_type"]["S"]}</Td>
                <Td>{customer["customer_description"]["S"]}</Td>
                <Td>{customer["customer_salesforce_id"]["S"]}</Td>
                <Td>
                  <Button
                    onClick={() => selectCustomer(customer)}
                    width={"100px"}
                  >
                    {selected ? "Deselect" : "Select"}
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default UpdateCustomer;
