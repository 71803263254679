import {
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
  Image,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { selectCustomerInfo } from "../../features/multiStepForm/FormSlice";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import { useAppSelector } from "../../app/hooks";
import { SiteAssetApi } from "../../utils/CustomerDeploymentApi/CDSiteAssestApi";
import {
  selectSiteEquipmentInfo,
  selectSiteLevelInfo,
} from "../../features/siteLevel/siteLevelSlice";
import TrashIcon from "../../assets/icons/trashIcon.svg";
import PlusIcon from "../../assets/icons/plus.svg";
import { NULL } from "sass";
import { useQuery } from "react-query";

interface LineCount {
  equipment_ref: string;
  line_name: string;
  traffic_type: string;
}

interface Sensor {
  type: string;
  manufacturer_name: string;
  manufacturer_id: string;
  device_area: string;
  router_name: string;
  line_counts: LineCount[];
}

interface SelectSensorsProps {
  id: number;
  sensorData: Sensor;
}

interface SensorsCompProps {
  customerId: string;
  id_token: string;
  siteId: string;
  level_id: string;
}

export const SelectSensors: React.FC<SensorsCompProps> = ({
  customerId,
  id_token,
  siteId,
  level_id,
}) => {
  const emptyLineCounts = {
    equipment_ref: "",
    line_name: "",
    traffic_type: "",
  };
  const emptySensorDetails = {
    type: "",
    manufacturer_name: "",
    manufacturer_id: "",
    device_area: "",
    router_name: "",
    line_counts: [emptyLineCounts],
  };

  //Env's
  const siteEquipmentInfo = useAppSelector(selectSiteEquipmentInfo);
  const createSensorMutation = SiteAssetApi.useCreateSensorOnLevel();

  // Variable's
  const [sensors, setSensors] = useState<SelectSensorsProps[]>([
    { id: 0, sensorData: emptySensorDetails },
  ]);

  // Fetch Sensor Data for the level
  const { data: levelSensorData, isFetching: isFetchingLevelSensorData } =
    useQuery("getSensorOnLevel",
      () => SiteAssetApi.getSensorOnLevel(id_token, customerId, siteId, level_id),
      {
        refetchOnWindowFocus: false, // Prevent refetching when window regains focus
        refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
        refetchInterval: false, // Disable automatic refetching
      }
    );

  useEffect(() => {
    console.log("equipment info", siteEquipmentInfo);
  }, [siteEquipmentInfo]);
  const addSensor = () => {
    setSensors([
      ...sensors,
      { id: sensors.length, sensorData: emptySensorDetails },
    ]);
  };

  const removeSensor = (id: number) => {
    if (sensors.length > 1) {
      setSensors(sensors.filter((sensor) => sensor.id !== id));
    }
  };

  const handleInputChange = (
    sensorId: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    lineCountIndex?: number
  ) => {
    const { name, value } = event.target;
    console.log(value);

    setSensors((prevSensors) =>
      prevSensors.map((sensor) => {
        if (sensor.id === sensorId) {
          // Handle changes inside line_counts if lineCountIndex is provided
          if (lineCountIndex !== undefined) {
            const updatedLineCounts = sensor.sensorData.line_counts.map(
              (lineCount, index) => {
                if (index === lineCountIndex) {
                  return { ...lineCount, [name]: value };
                }
                return lineCount;
              }
            );
            return {
              ...sensor,
              sensorData: {
                ...sensor.sensorData,
                line_counts: updatedLineCounts,
              },
            };
          }

          // Handle changes for non-nested sensorData fields
          return {
            ...sensor,
            sensorData: { ...sensor.sensorData, [name]: value },
          };
        }
        return sensor;
      })
    );
  };

  const addLineCount = (sensorId: number) => {
    setSensors((prevSensors) =>
      prevSensors.map((sensor) =>
        sensor.id === sensorId
          ? {
              ...sensor,
              sensorData: {
                ...sensor.sensorData,
                line_counts: [
                  ...sensor.sensorData.line_counts,
                  emptyLineCounts,
                ],
              },
            }
          : sensor
      )
    );
  };

  const removeLC = (sensorId: number) => {
    setSensors((prevSensors) =>
      prevSensors.map((sensor) =>
        sensor.id === sensorId
          ? {
              ...sensor,
              sensorData: {
                ...sensor.sensorData,
                line_counts: sensor.sensorData.line_counts.slice(0, -1),
              },
            }
          : sensor
      )
    );
  };

  // Submit the sensors to the backend
  const handleSubmit = () => {
    sensors.forEach((sensor) => {
      createSensorMutation.mutate({
        token: id_token,
        customerId: customerId,
        siteId: siteId,
        levelId: level_id,
        sensorInfo: sensor.sensorData,
      });
      console.log(sensor.sensorData);
    });
  };

  if (isFetchingLevelSensorData) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <Box>
        <VStack>
          <Table mb={4}>
            <Thead>
              <Tr>
                <Td fontWeight="bold">Sr. No</Td>
                <Td fontWeight="bold">Type</Td>
                <Td fontWeight="bold">Manufacturer Name</Td>
                <Td fontWeight="bold">Manufacturer ID</Td>
                <Td fontWeight="bold">Device Area</Td>
                <Td fontWeight="bold">Line Counts</Td>
                <Td fontWeight="bold">Router Name</Td>
                <Td fontWeight="bold"></Td>
                <Td fontWeight="bold"></Td>
              </Tr>
            </Thead>
            <Tbody>
              {sensors.map((sensor, index) => (
                <React.Fragment key={index}>
                  <Tr bg={"white"}>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Input
                        placeholder="Type"
                        value={sensor.sensorData.type}
                        name="type"
                        onChange={(e) => handleInputChange(sensor.id, e)}
                      />
                    </Td>
                    <Td>
                      <Input
                        placeholder="Manufacturer Name"
                        value={sensor.sensorData.manufacturer_name}
                        name="manufacturer_name"
                        onChange={(e) => handleInputChange(sensor.id, e)}
                      />
                    </Td>
                    <Td>
                      <Input
                        placeholder="Manufacturer ID"
                        value={sensor.sensorData.manufacturer_id}
                        name="manufacturer_id"
                        onChange={(e) => handleInputChange(sensor.id, e)}
                      />
                    </Td>
                    <Td>
                      <Input
                        placeholder="Device Area"
                        value={sensor.sensorData.device_area}
                        name="device_area"
                        onChange={(e) => handleInputChange(sensor.id, e)}
                      />
                    </Td>
                    <Td>
                      <Input
                        placeholder="Router Name"
                        value={sensor.sensorData.router_name}
                        name="router_name"
                        onChange={(e) => handleInputChange(sensor.id, e)}
                      />
                    </Td>
                    <Td>
                      <VStack>
                        {sensor.sensorData.line_counts.map(
                          (line_count, lineIndex) => (
                            <Tr key={lineIndex}>
                              <Td>
                                <select
                                  name="equipment_ref"
                                  value={line_count.equipment_ref}
                                  onChange={(e) =>
                                    handleInputChange(sensor.id, e, lineIndex)
                                  }
                                >
                                  {siteEquipmentInfo.map((equipment) => (
                                    <option
                                      key={equipment.equipment_id}
                                      value={equipment.equipment_id}
                                    >
                                      {equipment.equipment_name}
                                    </option>
                                  ))}
                                </select>
                              </Td>
                              <Td>
                                <Input
                                  placeholder="Line Name"
                                  value={line_count.line_name}
                                  name="line_name"
                                  onChange={(e) =>
                                    handleInputChange(sensor.id, e, lineIndex)
                                  }
                                />
                              </Td>
                              <Td>
                                <Input
                                  placeholder="Traffic Type"
                                  value={line_count.traffic_type}
                                  name="traffic_type"
                                  onChange={(e) =>
                                    handleInputChange(sensor.id, e, lineIndex)
                                  }
                                />
                              </Td>
                              {/* <Button onClick={() => removeLC(sensor.id)}>X</Button>
                               */}
                              <Td>
                                {sensor.sensorData.line_counts.length - 1 ===
                                lineIndex ? (
                                  <Box
                                    bg="blue.500"
                                    borderRadius="full"
                                    onClick={() => addLineCount(sensor.id)}
                                  >
                                    <IconButton
                                      aria-label="add"
                                      icon={
                                        <Image src={PlusIcon} boxSize="32px" />
                                      }
                                      variant="unstyled"
                                    />
                                  </Box>
                                ) : (
                                  <Td></Td>
                                )}
                              </Td>
                              <Td>
                                <Box
                                  bg="white"
                                  borderRadius="full"
                                  onClick={() => removeLC(sensor.id)}
                                >
                                  <IconButton
                                    aria-label="Delete"
                                    icon={
                                      <Image src={TrashIcon} boxSize="32px" />
                                    }
                                    variant="unstyled"
                                  />
                                </Box>
                              </Td>
                            </Tr>
                          )
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <VStack>
                        <Button onClick={() => removeSensor(sensor.id)}>
                          Remove
                        </Button>
                        {/* <Box
                      bg="blue.500"
                      borderRadius="full"
                      onClick={() => removeSensor(sensor.id)}
                      >
                      <IconButton
                      aria-label="Delete"
                      icon={<Image src={PlusIcon} boxSize="32px" />}
                      variant="unstyled"
                      />
                      </Box> */}
                        <Button onClick={() => handleSubmit()}>Submit</Button>
                      </VStack>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </VStack>
        <Button onClick={addSensor}>Add Sensor</Button>
      </Box>
    </>
  );
};
