import React from "react";
import {
    Box,
    BoxProps,
    Select as ChakraSelect,
    ResponsiveValue,
    SelectProps,
    useStyleConfig,
} from "@chakra-ui/react";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";

type CustomSelectProps = SelectProps & {
    options?: { value: string; label: string }[];
};
const Select: React.FC<CustomSelectProps> = React.forwardRef((props, ref) => {
    const { variant, size, ...rest } = props;

    return (
        <SelectGroup variant={variant} size={size}>
            <ChakraSelect
                ref={ref}
                variant={variant}
                size={size}
                {...rest}
                icon={<ChevronDown />}
                onClick={() => null}
            ></ChakraSelect>
        </SelectGroup>
    );
});

export default Select;

type SelectGroupProps = BoxProps & {
    variant?: ResponsiveValue<string>;
    size?: ResponsiveValue<string>;
};
const SelectGroup: React.FC<SelectGroupProps> = (props) => {
    const { variant, size, ...rest } = props;
    const variantStyles = useStyleConfig("SelectGroup", { variant });
    const sizeStyles = useStyleConfig("SelectGroup", { size });

    return <Box {...rest} __css={{ ...variantStyles, ...sizeStyles }}></Box>;
};
