import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    selectStep,
    setCustomerId,
    setStep,
} from "../../features/multiStepForm/FormSlice";
import "./CustomerOnboard.scss";
import {
    CustomerDeploymentApi,
    CustomerOnboradProps,
} from "../../utils/CustomerDeploymentApi/CustomerDeploymentApi";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import { Box, Button, Radio, RadioGroup, Stack, Text, Input } from "@chakra-ui/react";
import Select from "../../styles/UIComponents/CustomSelect";
import { BackButton } from "../BackButtonWitHeading/BackButtonWitHeading";

export const CustomerOnboard: React.FC = () => {
    // Env's
    const step = useAppSelector(selectStep);
    const dispatch = useAppDispatch();
    const mutation = CustomerDeploymentApi.useCreateCustomer();
    const token = useAppSelector(selectAuthTokens);

    // Variables
    const instanceType = ["METRO_LINE"];
    const [error, setError] = useState<Error>();
    const [customerData, setCustomerData] =
        React.useState<CustomerOnboradProps>({
            name: "",
            salesforce_id: "",
            description: "",
            instance_type: "",
            has_simulation: false,
        });
    const [hasSim, setSimulation] = useState<boolean>(false);

    /**
     * Go to Customer config
     * @param event
     */
    const handleNext = () => {
        dispatch(setStep(step + 1));
    };

    const handleChange = (newValue: string) => {
        setSimulation(newValue === "true");
    };

    const handleSubmit = () => {
        mutation.mutate({ customer: customerData, token: token!.id_token });
    };

    useEffect(() => {
        if (mutation.isSuccess) {
            dispatch(setCustomerId(mutation.data.customer_id));
            handleNext();
        }
        if (mutation.isError) {
            setError(mutation.error as Error);
        }
    }, [mutation.isSuccess, mutation.data, mutation.error]);

    return (
        <>
        <BackButton displayName="Onboard New Customer"/>
        <Box className="container">
            <Box className="input-value">
                <Text>Customer Name:</Text>
                <Input
                    type="text"
                    placeholder="Name"
                    onChange={(e) =>
                        setCustomerData({
                            ...customerData,
                            name: e.target.value,
                        })
                    }
                />
            </Box>
            <Box className="input-value">
                <Text>Saleforce id:</Text>
                <Input
                    type="text"
                    placeholder="id"
                    onChange={(e) =>
                        setCustomerData({
                            ...customerData,
                            salesforce_id: e.target.value,
                        })
                    }
                />
            </Box>
            <Box className="input-value">
                <Text>Description:</Text>
                <Input
                    type="text"
                    placeholder="..description"
                    onChange={(e) =>
                        setCustomerData({
                            ...customerData,
                            description: e.target.value,
                        })
                    }
                    />
            </Box>
            <Box className="select-value">
                <Text>Instance Type:</Text>
                <Select
                    className="dropdown"
                    value={customerData.instance_type}
                    onChange={(e) =>
                        setCustomerData({
                            ...customerData,
                            instance_type: e.target.value,
                        })
                    }
                    >
                    <option value="" disabled>
                        Select Instance Type
                    </option>
                    {instanceType.map((type, index) => (
                        <option key={index} value={type}>
                            {type}
                        </option>
                    ))}
                </Select>
            </Box>
            <Box>
                <RadioGroup onChange={handleChange} value={hasSim?.toString()}>
                    <Stack direction="row">
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                    </Stack>
                </RadioGroup>
            </Box>
            <Box className="Buttons">
                <Button onClick={handleSubmit}>Create Customer</Button>
            </Box>
            {mutation.isLoading && <span>Creating Customer.....</span>}
            {mutation.isError && <span>{error?.message}</span>}
        </Box>
                    </>
    );
};
