import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectCustomerInfo,
  selectStep,
  setAssetId,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import { useQuery } from "react-query";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";
import {
  Box,
  BoxProps,
  Flex,
  Select,
  Button,
  Text,
  HStack,
  Input,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
// import Input from "../../styles/UIComponents/CustomInput";
import {
  CustomerAssetsApi,
  CustomerAssetsDetails,
} from "../../utils/CustomerDeploymentApi/CDAssetsApi";
import { CustomerInfoFormValues } from "../../features/multiStepForm/customerInfo";
import { HorizontalLabel } from "../../styles/UIComponents/HorizontalLabel";
import { BackButton } from "../BackButtonWitHeading/BackButtonWitHeading";

export const CustomerInfoPage: React.FC = () => {
  // Env's
  const customerInfo = useAppSelector(selectCustomerInfo);
  const step = useAppSelector(selectStep);
  const tokens = useAppSelector(selectAuthTokens);
  const dispatch = useAppDispatch();

  // Variables
  const FLAG = "STATIONS-NETWORK";
  const [hasAssets, setHasAssets] = useState<boolean>(false);
  const fileType = ["GTFS_ZIP"];
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [customerAssetDetails, setCustomerAssetDetails] =
    useState<CustomerAssetsDetails>();

  // Api call for checking if file exists
  const {
    data: assetsData,
    isFetching: assetsFetching,
    isSuccess: assetFetchedSuccess,
  } = useQuery(
    CustomerAssetsApi.queryNames.GETCUSTOMERASSETS,
    () =>
      CustomerAssetsApi.getCustomerAssets(
        tokens!.id_token,
        customerInfo.customerDetails.customerId!
      ),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
      staleTime: 5000,
    }
  );

  // If assets exist's set the values to form
  useEffect(() => {
    if (assetFetchedSuccess) {
      if (assetsData.length === 0) {
        setHasAssets(false);
      } else if (
        (assetsData.length > 0 && "Assets data",
        assetsData[0]["name"].includes(FLAG))
      ) {
        setHasAssets(true);
        dispatch(setAssetId(assetsData[0]["id"]));
        setValue("stationsNetwork.filetype", assetsData[0]["type"]);
        setValue("stationsNetwork.description", assetsData[0]["description"]);
        setSelectedFileName(assetsData[0]["name"]);
      }
    }
  }, [assetFetchedSuccess]);

  // Form Initialisation
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CustomerInfoFormValues>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
      setHasAssets(true);
      setValue("stationsNetwork.files", e.target.files[0]); //Fix this
      setSelectedFileName(
        `${customerInfo.customerDetails.customerName}-${FLAG}-file`
      );
    }
  };

  const removeFile = () => {
    // setSelectedFile(null)
    // setHasAssets(false)
    // setSelectedFileName("")
    alert("Not allowed to Edit");
  };

  // Set customer asset details
  useEffect(() => {
    if (customerInfo.customerDetails.customerName !== undefined) {
      setCustomerAssetDetails({
        name: `${customerInfo.customerDetails.customerName}-${FLAG}-file`,
        type: watch("stationsNetwork.filetype"),
        description: watch("stationsNetwork.description"),
      });
    }
  }, [watch("stationsNetwork.filetype"), watch("stationsNetwork.description")]);

  // Upload file to S3
  const handleUploadFile = async () => {
    if (selectedFile && customerAssetDetails) {
      console.log("Uploading file...");
      await CustomerAssetsApi.uploadFileToS3(
        selectedFile,
        customerAssetDetails,
        tokens!.id_token,
        customerInfo.customerDetails.customerId!
      );
    } else {
      console.log("No file selected");
    }
  };

  const handleNext = () => {
    dispatch(setStep(step + 1));
  };

  const onSubmit: SubmitHandler<CustomerInfoFormValues> = (data) => {
    console.log(data);
  };

  const onSkip = () => {
    handleNext();
  };

  if (assetsFetching) return <div>Loading Details...</div>;

  return (
    <>
      <BackButton
        displayName={`Customer ${customerInfo.customerDetails.customerName}`}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "50px",
          padding: "10px 0 0 0",
          gap: "10px",
        }}
      >
        <HStack>
          <HorizontalLabel>Instance Type</HorizontalLabel>
          <Text
            style={{
              fontFamily: "var(--fontfamilymain)",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "32px",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
              color: "var(--color-brand-default, #1450F5)",
            }}
          >
            {customerInfo.customerDetails.instanceType}
          </Text>
        </HStack>
        <HStack>
          <HorizontalLabel>Salesforce Customer</HorizontalLabel>
          <Text
            style={{
              fontFamily: "var(--fontfamilymain)",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "32px",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
              color: "var(--color-brand-default, #1450F5)",
            }}
          >
            {customerInfo.customerDetails.customerName}(
            {customerInfo.customerDetails.customerSalesforceId})
          </Text>
        </HStack>
        <Box borderBottom="2px" borderColor="gray.300" mb={4} />
        <HStack>
          <HorizontalLabel>Stations Network</HorizontalLabel>
          <Select
            size={"sm"}
            placeholder="File Type"
            {...register("stationsNetwork.filetype", { required: true })}
            isInvalid={!!errors?.stationsNetwork?.filetype}
          >
            {fileType.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </HStack>
        <HStack>
          <HorizontalLabel>Description</HorizontalLabel>
          <Input
            placeholder="Description"
            type="text"
            w="300px"
            {...register("stationsNetwork.description", { required: true })}
            isInvalid={!!errors?.stationsNetwork?.description}
            onChange={(e) =>
              setValue("stationsNetwork.description", e.target.value)
            }
            value={watch("stationsNetwork.description")}
          />
        </HStack>
        <HStack>
          <HorizontalLabel>Upload File</HorizontalLabel>
          {selectedFileName === "" ? (
            <Box
              width="300px"
              display="flex"
              alignItems="center"
              outline="none"
              border="2px solid var(--color-border-strong, #959595)"
              borderRadius="100px"
              background="white"
              p="2"
              gap="4"
              height={"32px"}
            >
              <Text color="gray.500" w="200px" isTruncated>
                {selectedFileName}
              </Text>
              <Button
                colorScheme="blue"
                height={"24px"}
                fontSize={"12px"}
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                Choose file
              </Button>
              <Input
                id="fileInput"
                type="file"
                display="none"
                {...register("stationsNetwork.files")}
                isInvalid={!!errors?.stationsNetwork?.files}
                onChange={handleFileChange}
                accept=".zip"
              />
            </Box>
          ) : (
            <HStack>
              <Text>{selectedFileName}</Text>
              <Button onClick={removeFile}>x</Button>
            </HStack>
          )}
          <Button
            type="button"
            onClick={handleUploadFile}
            isDisabled={!hasAssets}
          >
            Upload
          </Button>
        </HStack>

        <Text mt={2} fontWeight="bold"></Text>
        {errors?.stationsNetwork?.filetype && (
          <Error>File type is required</Error>
        )}

        {errors?.stationsNetwork?.files && <Error>This is required</Error>}

        {errors?.stationsNetwork?.description && (
          <Error>Description is required</Error>
        )}
        <Text mt={2} fontWeight="bold"></Text>
        {selectedFile && <Text mt={2}>Selected file: {selectedFile.name}</Text>}
        {!hasAssets && (
          <Text mt={2} fontWeight="bold">
            No Assets Available
          </Text>
        )}
        {assetsFetching && (
          <Text mt={2} fontWeight="bold">
            No Assets Available
          </Text>
        )}
        <Box>
          {/* <Button type="submit" size="sm" isDisabled={!hasAssets ? true : false}>
            Submit
          </Button> */}
          <Button type="button" onClick={onSkip}>
            Go to Station For now
          </Button>
        </Box>
      </form>
    </>
  );
};

const Error: React.FC<BoxProps> = (props) => {
  return (
    <Flex
      w="100%"
      color="red.500"
      fontSize="small"
      justifyContent="center"
      align="center"
      {...props}
    />
  );
};
